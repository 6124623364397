import React from 'react';
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import {Helmet} from "react-helmet";
import GuideDetails from '../components/AreaGuideDetails/Advisors/Advisors'
import VideoBlock from '../components/AreaGuideDetails/VideoBlock/VideoBlock'
import OtherGuides from '../components/Guides/OtherGuides'

import Breadcrumbs from '../components/AreaGuideDetails/Breadcrumbs/GuideDetailsBreadcrumbs';

import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "../scss/custom.scss";

const GuideDetailsTemplate = (props) => {

  const GQLPage = props.data?.glstrapi?.guide;
  const OtherGuidesList = props.data?.glstrapi?.guides;
  //   const GlobalModule = props.data?.glstrapi?.officeDetailsModule;

  return (
    <React.Fragment>
      <div className="wrapper guide-details-template">
        <SEO title={GQLPage.Meta_Title} description={GQLPage.Meta_Description} image={GQLPage.Image ? GQLPage.Image.url : ''}/>
        <Header />
        <Breadcrumbs id={GQLPage?.Category[0]?.Details_Page_URL.id} Pagename={GQLPage.Title} />
        <GuideDetails {...GQLPage} />
        <VideoBlock {...GQLPage} />
        {OtherGuidesList.length > 0 &&
          <OtherGuides guides={OtherGuidesList} />
        }
        <Footer />

      </div>
    </React.Fragment>
  )
}
export default GuideDetailsTemplate;



export const pageQuery = graphql`
  query guideQuery($slug: ID!,$category: ID!) {
      glstrapi {
        guide(id: $slug, publicationState: LIVE) {
            Title
            Meta_Title
            Meta_Description
            Intro_Content
            Category {
              Details_Page_URL {
                id
              }
            }
            Content
            Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 1400
                    quality: 90
                    layout: FULL_WIDTH
                    transformOptions: { fit: COVER }
                  )
                }
              } 
            }
            People {
              Designation
              Image {
                url
                alternativeText
              }
              Name
              Phone
            }
          }
        
        guides(where: {id_ne: $slug,Category:{URL_in: $category}}, limit: 3) {
          id
          Title
          URL
          Tile_Image {
            url
            alternativeText
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  breakpoints: [436, 713]
                  webpOptions: {quality: 90}
                  width: 436
                  quality: 90
                )
              }
            } 
          }
          Category {
            id
            Name
            URL
            Sort
          }
        }


      }
  }
`